import React from "react";
import { Link } from "gatsby";

import StaticLayout from "../components/StaticLayout";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-666"
        className="post-666 post type-post status-publish format-standard hentry category-geen-categorie"
      >
        <header className="entry-header">
          <h1 className="entry-title">Gratis Proeflessen</h1>
          <div className="entry-meta" />
          {/* .entry-meta */}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <p>Ontdek jouw dansvibe tijdens onze gratis proeflessen!</p>
          <p>
            Op 8 en 19 september nodigen we je uit om vrijblijvend deel te nemen
            aan onze proeflessen. Kom langs en ontdek verschillende dansstijlen
            om te zien welke stijl jou het meest aanspreekt.
          </p>
          <p>Bekijk het onderstaande uurrooster:</p>
          <p>
            <strong>Zondag 8 september (Varenstraat 22a, Houthalen):</strong>
            <ul>
              <li>20:00 uur: Proefles Start-to-dance (koppeldans)</li>
            </ul>
          </p>
          <p>
            <strong>Donderdag 19 september (Boskestraat 3, Lummen):</strong>
            <ul>
              <li>18:00 uur: Proefles Solodans</li>
              <li>20:00 uur: Proefles Disco-boogie</li>
            </ul>
          </p>
          <p>We kijken ernaar uit om je te verwelkomen! </p>
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1920 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1925,0" />
        </svg>
      </div>
      <nav className="navigation post-navigation" role="navigation">
        <h2 className="screen-reader-text">Berichtnavigatie</h2>
        <div className="nav-links">
          <div className="nav-previous">
            <Link to="/">Terug naar de startpagina</Link>
          </div>
        </div>
      </nav>
    </main>
  </StaticLayout>
);

export default Page;
